import DingTalkLogin from "../views/DingTalkLogin.vue";

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
    {
        path: '/dingtalklogin',
        name: 'dingtalklogin',
        component: DingTalkLogin,
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "fail" */ '/src/views/404.vue')
    },
    {
        path: '/',
        redirect: '/dingtalklogin'
    }
]
