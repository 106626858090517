import axios from "axios";
import Vue from "vue";

export function dingTalkLogin (parameter) {
  return axios({
    url:  `${Vue.prototype.vueAppUrl}/dingtalk/login`,
    method: 'POST',
    params: parameter
  })
}
