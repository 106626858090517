<template>
  <div class="portal-box">
    <img src="../assets/a.svg" alt="description" class="img-box"/>
    <div class="content-wrap">
      <div class="txt-portal">{{ message }}</div>
      <el-button class="tip-b-box" type="primary" @click="setupDingTalkLogin" v-if="flag">
        没有账号，需要点击跳转至浏览器登录
      </el-button>
    </div>
  </div>
</template>
<script>
import {dingTalkLogin} from '/src/api/dingTalkLogin'

export default {
  name: 'DingTalkLogin',
  data() {
    return {
      message: '',
      redirect_url: '',
      params: '',
      flag: false,
      redirectBaseUrlMap: {
        1: `${process.env.VUE_APP_SOPURL}`,
        2: `${process.env.VUE_APP_ERPURL}`,
        3: `${process.env.VUE_APP_OEMURL}`
      }
    }
  },
  created() {
    if (!this.dDingJudgeEnvPlatform()){
      const that = this
      const type = that.$route.query.type
      const corpId = 'dingddd105713596751f35c2f4657eb6378f'
      dd.runtime.permission.requestAuthCode({
        corpId: corpId,
        onSuccess: function (info) {
          const code = info.code
          dingTalkLogin({code}).then(res => {
            // that.$message.warning(res)
            const token = res.data.result.token
            if (!token) {
              that.message = res.data.message
              that.message += '，没有系统账号，请联系管理员'
              that.flag = true
              return
            }
            const redirectBaseUrl = that.redirectBaseUrlMap[type]
            const params = that.$route.query
            for (const paramsKey in params) {
              if (paramsKey !== 'type' && paramsKey !== 'ddtab' && paramsKey !== 'ddpin') {
                that.params += `${paramsKey}=${params[paramsKey]}&`
              }
            }
            if (that.params.endsWith('&')) {
              that.params = that.params.slice(0, -1)
            }
            let encodeUrl
            if (that.params !== '') {
              encodeUrl = encodeURIComponent(that.params.substring(9))
            } else {
              encodeUrl = encodeURIComponent('/')
            }

            if (redirectBaseUrl !== undefined) {
              that.redirect_url = `${redirectBaseUrl}?redirect=${encodeUrl}&token=${token}`
              that.message = res.data.message + '，请查看浏览器页面'
              dd.biz.util.openLink({
                url: that.redirect_url,
                onSuccess: function () {
                },
                onFail: function (err) {
                  dd.alert({content: err.errorMessage})
                }
              })
            } else {
              that.$message.warning('不是本系统的合法参数，请再次校验')
            }
          })
        },
        onFail: function (err) {
          that.$message.warning(err.errorMessage + ' 请联系管理员')
        }
      })
    }
  },
  methods: {
    setupDingTalkLogin() {
      const type = this.$route.query.type
      const redirectBaseUrl = this.redirectBaseUrlMap[type]
      const params = this.$route.query
      this.params = ''
      for (const paramsKey in params) {
        if (paramsKey !== 'type' && paramsKey !== 'ddtab' && paramsKey !== 'ddpin') {
          this.params += `${paramsKey}=${params[paramsKey]}&`
        }
      }
      if (this.params.endsWith('&')) {
        this.params = this.params.slice(0, -1)
      }
      let encodeUrl = ''
      if (this.params !== '') {
        encodeUrl = encodeURIComponent(this.params.substring(9))
      } else {
        encodeUrl = encodeURIComponent('/')
      }
      if (redirectBaseUrl !== undefined) {
        const redirect_url = `${redirectBaseUrl}?redirect=${encodeUrl}`
        dd.biz.util.openLink({
          url: redirect_url,
          onSuccess: function () {
          },
          onFail: function (err) {
            dd.alert({content: err.errorMessage})
          }
        })
      }
    },
    dDingJudgeEnvPlatform() {
      let that = this
      if (dd.env.platform === 'notInDingTalk') {
        that.message = '请在钉钉工作台中打开'
        return false
      }
    }
  }
}
</script>
<style scoped>
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: #f0f0f0;
  font-family: 'Arial', sans-serif;
}

#centered-heading {
  text-align: center;
  color: #333;
  font-size: 2.5em;
  padding: 20px;
  border: 2px solid #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 80%;
}

.portal-box {
  height: 100%;
}

.img-box {
  width: 100%;
  height: 100%;
}

.txt-portal {
  font-size: 28px;
  text-align: center;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
}

.content-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.tip-b-box {
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .txt-portal, .tip-b-box {
    font-size: 14px;
  }
}
</style>
