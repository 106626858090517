import router from './router' // 假设你的路由实例在 router.js 文件中

router.beforeEach((to, from, next) => {
  // 检查目标路由是否在路由表中
  const foundRoute = router.options.routes.find(route => route.path === to.path)

  if (!foundRoute) {
    // 如果目标路由不在路由表中，则跳转到 404 页面
    next('/404')
  } else {
    // 目标路由在路由表中，继续正常跳转
    next()
  }
})
