import {constantRouterMap} from './router.config'
import Router from 'vue-router'
import Vue from "vue";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({y: 0}),
    routes: constantRouterMap
})

