import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "./permission"

Vue.config.productionTip = false
Vue.prototype.vueAppUrl = process.env.VUE_APP_URL
Vue.prototype.vueAppTms = process.env.VUE_APP_TMS
Vue.prototype.erpAppUrl = process.env.VUE_APP_ERPURL
Vue.prototype.sopAppUrl = process.env.VUE_APP_OEMURL
Vue.prototype.oemAppUrl = process.env.VUE_APP_SOPURL
Vue.use(ElementUI)
const app = new Vue({
    router,
    render: h => h(App)
})


app.$mount('#app')

