<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>
export default  {
  name:  'App'
}
</script>
<style>
@media (max-width: 1365px) {
  #app .el-dialog__wrapper.full-height .el-dialog {
    width: 800px;
  }
}
@media (min-width: 1366px) {
  #app .el-dialog__wrapper.full-height .el-dialog {
    width: 1200px;
  }
}
@media (min-width: 1920px) {
  #app .el-dialog__wrapper.full-height .el-dialog {
    width: 1560px;
  }
}
</style>
